import {VALID_TAB} from "./tabsAction"
import {RESET_REGISTRATION} from "../registration/registrationAction";

const initialState = {
    informationTab: false,
    membersTab: false,
    yourDataTab: false,
    summaryTab: false,
}

const groupTabsReducer = (state = initialState, action) => {
    switch (action.type) {
        case VALID_TAB:
            return {
                ...state,
                [action.tab]: true
            }
        case RESET_REGISTRATION:
            return initialState
        default:
            return state
    }
}

export default groupTabsReducer
