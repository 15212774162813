import React from "react"
import { Provider } from "react-redux"
import {applyMiddleware, createStore} from "redux"
import {createLogger} from "redux-logger"
import thunk from "redux-thunk"

import combineReducers from "./src/redux/combineReducers"

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger())
}

const store = createStore(combineReducers, applyMiddleware(...middleware))

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    return <Provider store={store}>{element}</Provider>
}
