export const lmic =
    [
        "Angola",
        "Bangladesh",
        "Bhutan",
        "Bolivia",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Comoros",
        "Congo, Rep.",
        "Côte d'Ivoire",
        "Djibouti",
        "Egypt, Arab Rep.",
        "El Salvador",
        "Eswatini",
        "Ghana",
        "Honduras",
        "India",
        "Indonesia",
        "Kenya",
        "Kiribati",
        "Kyrgyz Republic",
        "Lao PDR",
        "Lesotho",
        "Mauritania",
        "Micronesia, Fed. Sts.",
        "Moldova",
        "Mongolia",
        "Morocco",
        "Myanmar",
        "Nicaragua",
        "Nigeria",
        "Pakistan",
        "Papua New Guinea",
        "Philippines",
        "São Tomé and Principe",
        "Senegal",
        "Solomon Islands",
        "Sudan",
        "Timor-Leste",
        "Tunisia",
        "Ukraine",
        "Uzbekistan",
        "Vanuatu",
        "Vietnam",
        "West Bank and Gaza",
        "Zambia",
        "Zimbabwe",
        "Afghanistan",
        "Benin",
        "Burkina Faso",
        "Burundi",
        "Central African Republic",
        "Chad",
        "Congo, Dem. Rep.",
        "Eritrea",
        "Ethiopia",
        "Gambia, The",
        "Guinea",
        "Guinea-Bissau",
        "Haiti",
        "Korea, Dem. People's Rep.",
        "Liberia",
        "Madagascar",
        "Malawi",
        "Mali",
        "Mozambique",
        "Nepal",
        "Niger",
        "Rwanda",
        "Sierra Leone",
        "Somalia",
        "South Sudan",
        "Syrian Arab Republic",
        "Tajikistan",
        "Tanzania",
        "Togo",
        "Uganda",
        "Yemen, Rep.",
    ]
