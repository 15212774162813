import {VALID_TAB} from "./tabsAction"
import {RESET_REGISTRATION} from "../registration/registrationAction"

const initialState = {
    ticketTab: false,
    optionsTab: false,
    informationTab: false,
    yourDataTab: false,
    documentsTab: false,
    summaryTab: false,
}

const tabsReducer = (state = initialState, action) => {
    switch (action.type) {
        case VALID_TAB:
            return {
                ...state,
                [action.tab]: true
            }
        case RESET_REGISTRATION:
            return initialState
        default:
            return state
    }
}

export default tabsReducer
